@import 'bootstrap/dist/css/bootstrap.min.css';

.styled-input {
  width: 300px;
  padding: 10px;
  font-size: 16px;
  border: 2px solid #8898aa;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s;

  /* Hover effect */
  &:hover {
    border-color: #555;
  }

  /* Focus effect */
  &:focus {
    border-color: #007bff;
  }
}
.blue-button {
  background-color: #00D4FF; /* Sky blue color */
  color: #fff; /* Text color */
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  /* Hover effect */
  &:hover {
    background-color: #5F9EA0; /* Slightly darker blue on hover */
    border: none;
  }
  &:focus {
    border: none;
  }
}
/* Styles for the input row */
.input-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

/* Styles for the input container */
.input-container {
  flex: 1;
  margin-right: 10px;
}

/* Styles for the labels */
.input-label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: white;
}

/* Styles for the input fields */
.input-field {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.course-input{
  margin-top:7px;
  padding: 9px;
}
.course-input::placeholder {
  font-size: 15px; /* Set your desired font size */
}

.employee-card {
  background-color: #f3f3f3;
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.employee-id, .employee-name, .total-exp {
  margin: 5px 0;
}

.employee-id strong, .employee-name strong, .total-exp strong {
  font-weight: bold;
  margin-right: 5px;
}

/* Media Query for screens with a max-width of 600px (adjust as needed) */
@media (max-width: 600px) {
  .employee-card {
    padding: 5px;
  }
}
.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: .25em .25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: .375rem;
  opacity: var(--bs-btn-close-opacity)
}

.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity)
}

.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity)
}

/* logout div css */
#logoutDiv {
  background-color: #3498db;
  color: #fff;
  padding: 10px;
  cursor: pointer;
}

#logoutDropdown {
  /* display: none; */
  position: absolute;
  right:"100px";
  top:"100px";
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
}

#logoutDropdown ul {
  list-style: none;
  padding: 0;
}

#logoutDropdown ul li {
  margin: 5px 0;
}

.hidden {
  display: none;
}
.List {
  max-height: calc(100% - 70px);
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

/* / Style for the thumb of the scrollbar / */
.List::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2); 
  border-radius: 20px; 
}

/* / Hide scrollbar track when not hovered / */
.List::-webkit-scrollbar {
  width: 8px; 
  background-color: transparent; 
}

.autocomplete {
  width: 20vw;
}

.MuiPaginationItem-page.Mui-selected {
  background-color: #00D4FF;
  color: white;
}
/* .pagination{
  background-color: #00D4FF;
  color: white;
} */
/* Style for hovered page */
.MuiPaginationItem-page:hover {
  background-color: #00D4FF;
  color: white;
}

.swal2-container {
  width: "50%" !important;
  z-index: 20000 !important;
}

/* Styles for the toggle switch */
/* Styles for the toggle switch */
.switch {
  margin-top: 6px;
  position: relative;
  display: inline-block;
  width: 90px;
  height: 34px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 34px;
  transition: 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider p {
  margin: 0;
  color: white;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
}

input:checked + .slider {
  background-color:rgb(0, 52, 89);
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  transform: translateX(55px);
}


/* menu bar */
body {
  background-color: #EDEDED;
}
.circular-menu {
  position: fixed;
  bottom: 1em;
  right: 1em;
}

.circular-menu .floating-btn {
  display: block;
  width: 3.5em;
  height: 3.5em;
  border-radius: 50%;
  background-color: hsl(4, 98%, 60%);
  box-shadow: 0 2px 5px 0 hsla(0, 0%, 0%, .26);  
  color: hsl(0, 0%, 100%);
  text-align: center;
  line-height: 3.9;
  cursor: pointer;
  outline: 0;
}

.circular-menu.active .floating-btn {
  box-shadow: inset 0 0 3px hsla(0, 0%, 0%, .3);
}

.circular-menu .floating-btn:active {
  box-shadow: 0 4px 8px 0 hsla(0, 0%, 0%, .4);
}

.circular-menu .floating-btn i {
  font-size: 1.3em;
  transition: transform .2s;  
}

.circular-menu.active .floating-btn i {
  transform: rotate(-45deg);
}

.circular-menu:after {
  display: block;
  content: ' ';
  width: 3.5em;
  height: 3.5em;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -2;
  background-color: hsl(4, 98%, 60%);
  transition: all .3s ease;
}

.circular-menu.active:after {
  transform: scale3d(4, 4, 1);
  transition-timing-function: cubic-bezier(.68, 1.55, .265, 1);
}

.circular-menu .items-wrapper {
  padding: 0;
  margin: 0;
}

.circular-menu .menu-item {
  position: absolute;
  top: .2em;
  right: .2em;
  z-index: -1;
  display: block;
  text-decoration: none;
  color: hsl(0, 0%, 100%);
  font-size: 1em;
  width: 3em;
  height: 3em;
  border-radius: 50%;
  text-align: center;
  line-height: 3;
  background-color: hsla(0,0%,0%,.1);
  transition: transform .3s ease, background .2s ease;
}

.circular-menu .menu-item:hover {
  background-color: hsla(0,0%,0%,.3);
}

.circular-menu.active .menu-item {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.circular-menu.active .menu-item:nth-child(1) {
  transform: translate3d(1em,-7em,0);
}

.circular-menu.active .menu-item:nth-child(2) {
  transform: translate3d(-3.5em,-6.3em,0);
}

.circular-menu.active .menu-item:nth-child(3) {
  transform: translate3d(-6.5em,-3.2em,0);
}

.circular-menu.active .menu-item:nth-child(4) {
  transform: translate3d(-7em,1em,0);
}

/**
 * The other theme for this menu
 */

.circular-menu.circular-menu-left {
  right: auto; 
  left: 1em;
}

.circular-menu.circular-menu-left .floating-btn {
  background-color: #00D4FF;
}

.circular-menu.circular-menu-left:after {
  background-color: #00D4FF;
}

.circular-menu.circular-menu-left.active .floating-btn i {
  transform: rotate(90deg);
}

.circular-menu.circular-menu-left.active .menu-item:nth-child(1) {
  transform: translate3d(0em,-5em,0);
}

.circular-menu.circular-menu-left.active .menu-item:nth-child(2) {
  transform: translate3d(3.5em,-3.5em,0);
}

.circular-menu.circular-menu-left.active .menu-item:nth-child(3) {
  transform: translate3d(5em,0em,0);
}

.circular-menu.circular-menu-left.active .menu-item:nth-child(4) {
  transform: translate3d(7em,1em,0);
}

